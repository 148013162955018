/* @import '_variables';
@import '_menu';

html {
  min-height: 100%;
  font-size: 16px;

  @media screen and (min-width: 600px) {
    font-size: 18px;
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: hsla(210, 50%, 70%, 0.1);
  color: #333;

  font-family: 'Open Sans', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 1.4;
}

ol {
  line-height: 1.4;
}

li {
  margin-bottom: 0.5rem;
}

strong {
  font-weight: 700;
}

a {
  color: #005596;
}
a:hover {
  color: #002c4e;
}

img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

.site-header {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: center;
  }
}

.site-header__link {
  text-decoration: none;
}

.site-header__title {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 8px 16px;

  border-radius: 3px;

  color: #2a547e;
}

.header {
  padding: 100px 16px;

  background: hsla(210, 39%, 80%, 1);
  box-shadow: inset 0 0 20px 0px #3333333d;

  &__title {
    color: hsla(210, 50%, 33%, 1);
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    text-align: center;
  }
}

.main {
  margin: 0 auto;
}

.footer {
  background-color: hsla(210, 40%, 20%, 1);
  color: #fff;
  font-size: 16px;
  padding: 60px 16px;
  display: flex;
  justify-content: center;

  a {
    color: #fff;
  }

  span {
    padding: 0 16px;
  }
}

.article {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;

  &__header {
    padding: 50px 0 20px 0;
    text-align: center;

    h1 {
      font-size: 3rem;
      line-height: 1;
      margin: 0;
    }

    h2 {
      margin: 0;
      color: #555;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.4;
      margin-top: 0.5rem;
    }

    &__image {
      margin-top: 40px;
      max-height: 360px;
      object-fit: cover;
      object-position: center;
      max-width: 1000px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 30px;

    @media screen and (min-width: 600px) {
      flex-direction: row;
    }
  }

  &__text {
    flex: 1;

    p:first-child {
      margin-top: 0;
    }
  }

  &__image {
    flex: 1;
    margin-left: 0;
    margin-bottom: 24px;

    @media screen and (min-width: 600px) {
      margin-left: 60px;
    }

    img {
      border: 1px solid hsla(210, 35%, 50%, 0.5);
      border-radius: 3px;
    }
  }

  &__section {
    max-width: 700px;
    margin: 80px auto 0;
    padding: 0 16px 60px;

    & + .article__section {
      margin-top: 0;
    }

    &--wide {
      max-width: 1000px;
    }

    p {
      &.preamble {
        font-size: 1.4rem;
        line-height: 1.2;
      }
    }
  }
}

.table {
  background-color: hsla(203, 0%, 100%, 1);
  border-spacing: 0;
  margin-bottom: 40px;
  width: 100%;

  @media screen and (min-width: 600px) {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  }

  &__head td {
    background-color: hsla(203, 0%, 100%, 1);
    border-bottom: 4px solid hsla(210, 80%, 40%, 0.5);
    font-weight: 700;
    font-size: 16px;
  }

  &__body {
    tr:nth-child(odd) {
      background-color: hsla(210, 80%, 80%, 0.3);
    }

    td:first-child {
      font-weight: 700;
    }
  }
}

.table--center-2-3-4 {
  td {
    @media only screen and (min-width: 600px) {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        text-align: center;
      }
    }
  }
}

.table--center-2-3 {
  td {
    @media only screen and (min-width: 600px) {
      &:nth-child(2),
      &:nth-child(3) {
        text-align: center;
      }
    }
  }
}

td {
  font-size: 14px;
  padding: 8px;
  border-left: 1px solid hsla(210, 80%, 80%, 0.3);
  border-top: 1px solid hsla(210, 80%, 80%, 0.3);

  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }

  &:first-child {
    @media only screen and (min-width: 600px) {
      border-left: 0;
    }
  }
}

tr {
  &:first-child td {
    @media only screen and (min-width: 600px) {
      border-top: 0;
    }
  }
}

@media only screen and (max-width: 599px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border-bottom: 16px solid #f3f7fb;
  }

  td {
    position: relative;
    padding-left: 50%;
    border-right: 1px solid hsla(203, 0%, 85%, 1);

    &:first-child {
      font-weight: 700;
    }

    &:last-child {
      border-bottom: 1px solid hsla(203, 0%, 85%, 1);
    }

    &:before {
      position: absolute;
      top: 7px;
      left: 6px;
      width: 45%;
    }
  }

  .table--1 {
    td {
      &:nth-of-type(1):before {
        content: 'Leverantör';
      }
      &:nth-of-type(2):before {
        content: 'Branschanpassade';
      }
      &:nth-of-type(3):before {
        content: 'Service';
      }
      &:nth-of-type(4):before {
        content: 'Gratis Testperiod';
      }
      &:nth-of-type(5):before {
        content: 'Vår utvärdering av CRM-verktyget';
      }
    }
  }

  .table--2 {
    td {
      &:nth-of-type(1):before {
        content: 'Leverantör';
      }
      &:nth-of-type(2):before {
        content: 'Företagsstorlekar';
      }
      &:nth-of-type(3):before {
        content: 'Service';
      }
      &:nth-of-type(4):before {
        content: 'Gratis Testperiod';
      }
      &:nth-of-type(5):before {
        content: 'Vår utvärdering av CRM-verktyget';
      }
    }
  }

  .table--3 {
    td {
      &:nth-of-type(1):before {
        content: 'Leverantör';
      }
      &:nth-of-type(2):before {
        content: 'Säljfokus';
      }
      &:nth-of-type(3):before {
        content: 'Gratis Testperiod';
      }
      &:nth-of-type(4):before {
        content: 'Vår utvärdering av säljorienterade CRM-system';
      }
    }
  }
} */

:root {
  --main-bg-color: pink;
}

html {
  min-height: 100%;
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  background-color: hsla(210, 50%, 70%, 0.1);
  color: #333;

  font-family: 'Open Sans', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 1.4;
}

ol {
  line-height: 1.4;
}

li {
  margin-bottom: 0.5rem;
}

strong {
  font-weight: 700;
}

a {
  color: #005596;
}
a:hover {
  color: #002c4e;
}

img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

.site-header {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.site-header__link {
  text-decoration: none;
}

.site-header__title {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 8px 16px;

  border-radius: 3px;

  color: #2a547e;
}

.header {
  padding: 100px 16px;

  background: hsla(210, 39%, 80%, 1);
  box-shadow: inset 0 0 20px 0px #3333333d;
}

.header__title {
  color: hsla(210, 50%, 33%, 1);
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

.main {
  margin: 0 auto;
}

.footer {
  background-color: hsla(210, 40%, 20%, 1);
  color: #fff;
  font-size: 16px;
  padding: 60px 16px;
  display: flex;
  justify-content: center;
}

.footer a {
  color: #fff;
}

.footer span {
  padding: 0 16px;
}

.article {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}

.article__header {
  padding: 50px 0 20px 0;
  text-align: center;
}
.article__header h1 {
  font-size: 3rem;
  line-height: 1;
  margin: 0;
}
.article__header h2 {
  margin: 0;
  color: #555;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 0.5rem;
}
.article__header__image {
  margin-top: 40px;
  max-height: 360px;
  object-fit: cover;
  object-position: center;
  max-width: 1000px;
}
.article__content {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 30px;
}
@media screen and (min-width: 600px) {
  .article__content {
    flex-direction: row;
 }
}
.article__text {
  flex: 1;
}
.article__text p:first-child {
  margin-top: 0;
}
.article__image {
  flex: 1;
  margin-left: 0;
  margin-bottom: 24px;
}
@media screen and (min-width: 600px) {
  .article__image {
    margin-left: 60px;
 }
}
.article__image img {
  border: 1px solid rgba(83, 128, 172, 0.5);
  border-radius: 3px;
}
.article__section {
  max-width: 700px;
  margin: 80px auto 0;
  padding: 0 16px 60px;
}
.article__section + .article__section {
  margin-top: 0;
}
.article__section--wide {
  max-width: 1000px;
}
.article__section p.preamble {
  font-size: 1.4rem;
  line-height: 1.2;
}


.table {
  background-color: rgba(255, 255, 255, 1);
  border-spacing: 0;
  margin-bottom: 40px;
  width: 100%;
}
@media screen and (min-width: 600px) {
  .table {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
 }
}
.table__head td {
  background-color: rgba(255, 255, 255, 1);
  border-bottom: 4px solid rgba(20, 102, 184, 0.5);
  font-weight: 700;
  font-size: 16px;
}
.table__body tr:nth-child(odd) {
  background-color: rgba(163, 204, 245, 0.3);
}
.table__body td:first-child {
  font-weight: 700;
}
@media only screen and (min-width: 600px) {
  .table--center-2-3-4 td:nth-child(2), .table--center-2-3-4 td:nth-child(3), .table--center-2-3-4 td:nth-child(4) {
    text-align: center;
 }
}
@media only screen and (min-width: 600px) {
  .table--center-2-3 td:nth-child(2), .table--center-2-3 td:nth-child(3) {
    text-align: center;
 }
}
td {
  font-size: 14px;
  padding: 8px;
  border-left: 1px solid rgba(163, 204, 245, 0.3);
  border-top: 1px solid rgba(163, 204, 245, 0.3);
}
@media only screen and (max-width: 400px) {
  td {
    font-size: 14px;
 }
}
@media only screen and (min-width: 600px) {
  td:first-child {
    border-left: 0;
 }
}
@media only screen and (min-width: 600px) {
  tr:first-child td {
    border-top: 0;
 }
}

@media only screen and (max-width: 599px) {
  table, thead, tbody, th, td, tr {
    display: block;
 }
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
 }
  tr {
    border-bottom: 16px solid #f3f7fb;
 }
  td {
    position: relative;
    padding-left: 50%;
    border-right: 1px solid rgba(217, 217, 217, 1);
 }
  td:first-child {
    font-weight: 700;
 }
  td:last-child {
    border-bottom: 1px solid rgba(217, 217, 217, 1);
 }
  td:before {
    position: absolute;
    top: 7px;
    left: 6px;
    width: 45%;
 }
  .table--1 td:nth-of-type(1):before {
    content: 'Leverantör';
 }
  .table--1 td:nth-of-type(2):before {
    content: 'Branschanpassade';
 }
  .table--1 td:nth-of-type(3):before {
    content: 'Service';
 }
  .table--1 td:nth-of-type(4):before {
    content: 'Gratis Testperiod';
 }
  .table--1 td:nth-of-type(5):before {
    content: 'Vår utvärdering av CRM-verktyget';
 }
  .table--2 td:nth-of-type(1):before {
    content: 'Leverantör';
 }
  .table--2 td:nth-of-type(2):before {
    content: 'Företagsstorlekar';
 }
  .table--2 td:nth-of-type(3):before {
    content: 'Service';
 }
  .table--2 td:nth-of-type(4):before {
    content: 'Gratis Testperiod';
 }
  .table--2 td:nth-of-type(5):before {
    content: 'Vår utvärdering av CRM-verktyget';
 }
  .table--3 td:nth-of-type(1):before {
    content: 'Leverantör';
 }
  .table--3 td:nth-of-type(2):before {
    content: 'Säljfokus';
 }
  .table--3 td:nth-of-type(3):before {
    content: 'Gratis Testperiod';
 }
  .table--3 td:nth-of-type(4):before {
    content: 'Vår utvärdering av säljorienterade CRM-system';
 }
}

@media screen and (min-width: 600px) {
  html {
    font-size: 16px;
  }

  .site-header {
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (min-width: 767px) {
  .menu {
    position: relative;
    z-index: 99;
 }
}
.menu .active {
  text-decoration: none;
  color: #333;
  pointer-events: none;
  font-weight: 700;
}
@media screen and (min-width: 767px) {
  .menu .active {
    font-weight: 400;
 }
}
.menu__toggle {
  -webkit-appearance: none;
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 16px;
  padding: 8px 16px;
  border: 1px solid #666;
  border-radius: 3px;
  background-color: #fff;
  z-index: 9;
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .menu__toggle {
    display: none;
 }
}
.menu__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(-100%);
  transition: transform 300ms ease-in-out;
  z-index: 8;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #fff;
}
.menu__list--open {
  transform: translateY(0);
}
@media screen and (min-width: 767px) {
  .menu__list {
    flex-direction: row;
    position: relative;
    transform: translateY(0);
    background-color: transparent;
 }
}
.menu__list li {
  margin-bottom: 0;
}
.menu__list a {
  color: #333;
  text-decoration: none;
}
.menu__list__item {
  position: relative;
  padding: 0 16px;
  margin: 0;
}
.menu__list__item:hover .menu__foldout {
  display: block;
}
.menu__list__link {
  position: relative;
  display: block;
  padding: 8px;
}
@media screen and (min-width: 767px) {
  .menu__list__link {
    padding: 16px 8px;
 }
}
@media screen and (min-width: 767px) {
  .menu__list__link.active::before {
    content: '';
    position: absolute;
    bottom: 8px;
    height: 2px;
    left: 8px;
    right: 8px;
    background-color: rgba(31, 51, 71, 1);
 }
}
